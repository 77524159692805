<template>
  <Card>
    <Row type="flex" justify="start">
      <Col span="9" style="text-align: center; padding: 16px;">
        <img class="profile-user-img img-responsive img-circle" :src="getHeadImg()" alt="User profile picture">
        <div class="padding-top-20"></div>
        <h2 class="profile-username text-center">{{ this.$store.getters.name }}</h2>
        <div class="padding-top-20"></div>
        <p class="font-grey text-center"><Tag v-for="(perm,permIdx) in getPermissions()" :key="permIdx">{{perm}}</Tag></p>
        <div class="padding-top-20"></div>
        <Button type="error" size="large" long icon="ios-log-out" @click="signOut">登出</Button>
        <div class="padding-top-10"></div>
        <Button type="default" size="large" long icon="ios-arrow-back" @click="$router.back()">返回</Button>
      </Col>
      <Col><Divider type="vertical" style="height:100%" /></Col>
      <Col span="14" style="padding: 16px;">
        <div class="block-header">
          <span class="block-header-text">个人基本信息</span>
        </div>
        <div class="padding-top-20"></div>
        <ul class="list-group list-group-unbordered">
          <li class="list-group-item">
            <b>用户登录名</b> <span class="pull-right font-grey">{{ this.$store.getters.userName }}</span>
          </li>
          <li class="list-group-item">
            <b>身份证号</b> <span class="pull-right font-grey">{{ this.$store.getters.idNumber }}</span>
          </li>
          <li class="list-group-item">
            <b>性别</b> <span class="pull-right font-grey"><Tag :color="$common.genderColor[this.$store.getters.isMale?'1':'0']">{{ $common.gender[this.$store.getters.isMale?'1':'0'] }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>手机号码</b> <span class="pull-right font-grey">{{ this.$store.getters.mobilePhoneNumber }}</span>
          </li>
          <li class="list-group-item">
            <b>生日</b> <span class="pull-right font-grey">{{ $moment(this.$store.getters.birthday).format('YYYY-MM-DD') }}</span>
          </li>
          <li class="list-group-item">
            <b>IC卡编码</b> <span class="pull-right font-grey">{{ this.$store.getters.icCardId }}</span>
          </li>
          <li class="list-group-item">
            <b>微信openId</b> <span class="pull-right font-grey">{{ this.$store.getters.wechat }}</span>
          </li>
          <li class="list-group-item">
            <b>邮箱</b> <span class="pull-right font-grey">{{ this.$store.getters.email }}</span>
          </li>
          <li class="list-group-item">
            <b>注册日期</b> <span class="pull-right font-grey">{{ $moment(this.$store.getters.creationTime).format('YYYY-MM-DD') }}</span>
          </li>
        </ul>
        <Button type="warning" size="large" @click="showMyPwdForm=true">修改密码</Button>
      </Col>
    </Row>
    <myPwdForm
      :show="showMyPwdForm"
      @close="closeMyPwdForm"
    ></myPwdForm>
  </Card>
</template>

<script>
import myPwdForm from './MyUserPwd'
export default {
  name: 'profile',
  components: {
    myPwdForm
  },
  data () {
    return {
      showMyPwdForm: false
    }
  },
  mounted () {
  },
  methods: {
    closeMyPwdForm () {
      this.showMyPwdForm = false
    },
    signOut () {
      this.$Spin.show()
      this.$Api.Auth(this).logout().then(() => {
        this.$Message.success('登出成功!')
        this.$Spin.hide()
        this.$store.dispatch('logout')
        this.$router.push({path: '/login'})
      }).catch(err => {
        this.$Spin.hide()
        this.$Message.error('登出失败!' + err.message)
        this.$store.dispatch('logout')
        this.$router.push({path: '/login'})
      })
    },
    getHeadImg () {
      let img = require('./../../assets/libs/img/user1-128x128.jpg')
      if (!this.$store.getters.isMale) {
        img = require('./../../assets/libs/img/user3-128x128.jpg')
      }
      return img
    },
    getPermissions () {
      let perms = []
      if (this.$store.getters.permissions) {
        this.$store.getters.permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
